import { graphql, useStaticQuery } from "gatsby";

const useGeneralSiteInfo = () => {
  const {
    allContentfulGeneralInformationAboutTheWebsite: contentfulData,
    site,
  } = useStaticQuery(graphql`
    {
      allContentfulGeneralInformationAboutTheWebsite {
        edges {
          node {
            cookiepolicy {
              file {
                url
              }
            }
            companyIcon {
              gatsbyImageData(formats: NO_CHANGE)
            }
            companyMotto
            telefonszm
            email
            tiktok
            privacypolicy {
              file {
                url
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
          title
          locale
          staticRoutes {
            isVisible
            isExternal
            name
            linkroot
            slug
            category
          }
        }
      }
    }
  `);
  const siteMetadata = site?.siteMetadata;

  const parsedResult = { ...contentfulData?.edges[0]?.node, ...siteMetadata };

  return parsedResult;
};
export default useGeneralSiteInfo;
