import * as React from "react";
import useGeneralSiteInfo from "../core/hooks/useGeneralSiteInfo";

const CookiePolicy = () => {
  const pageInfo = useGeneralSiteInfo();

  return (
    <>
      <meta
        http-equiv="refresh"
        content={`0; URL= https://${pageInfo?.cookiepolicy?.file?.url}`}
      />
      <div
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "90%",
          position: "absolute",
        }}
      >
        <p style={{ fontSize: "1.2rem" }} className="text-center">
          <b>
            You will be redirected shortly, or{" "}
            <a
              style={{ color: "#8900AF" }}
              href={`https://${pageInfo?.cookiepolicy?.file?.url}`}
            >
              click here
            </a>
            .
          </b>
        </p>
      </div>
    </>
  );
};

export default CookiePolicy;
